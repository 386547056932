import textureCharacterAnimationBunny from '../assets/characters/bunny/animation.png?as=webp&width=1024';
import textureCharacterAnimationTortoise from '../assets/characters/tortoise/animation.png?as=webp&width=1024';
import textureCharacterAnimationHedgehog from '../assets/characters/hedgehog/animation.png?as=webp&width=1024';
import textureCharacterAnimationFox from '../assets/characters/fox/animation.png?as=webp&width=1024';
import textureCharacterAnimationFrog from '../assets/characters/frog/animation.png?as=webp&width=1024';
import textureCharacterAnimationBadger from '../assets/characters/badger/animation.png?as=webp&width=1024';
import textureCharacterAnimationBear from '../assets/characters/bear/animation.png?as=webp&width=1024';

import tileClearing from '../assets/tiles/tile_grass_empty.png?as=webp&width=256';
import tileTrees from '../assets/tiles/tile_trees.png?as=webp&width=256';
import tileHardwood from '../assets/tiles/tile_hardwood.png?as=webp&width=256';
import tileStones from '../assets/tiles/tile_stones.png?as=webp&width=256';
import tileTreesStump1 from '../assets/tiles/tile_trees_stump_1.png?as=webp&width=256';
import tileTreesStump2 from '../assets/tiles/tile_trees_stump_2.png?as=webp&width=256';
import tileTreesStump3 from '../assets/tiles/tile_trees_stump_3.png?as=webp&width=256';
import tilePond from '../assets/tiles/tile_pond.png?as=webp&width=256';
import cloud1 from '../assets/particles/cloud1.png?as=webp&width=256';
import cloud3 from '../assets/particles/cloud2.png?as=webp&width=256';
import cloud2 from '../assets/particles/cloud3.png?as=webp&width=256';
import cloud4 from '../assets/particles/cloud4.png?as=webp&width=256';

const cache: any = {};

async function loadImageToDataUrl(url: string) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.setAttribute('crossOrigin', 'anonymous');

		img.onload = function (e: any) {
			var canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;

			const ctx = canvas.getContext("2d");
			ctx!.drawImage(img, 0, 0);

			var dataURL = canvas.toDataURL("image/png");

			cache[url] = dataURL;
			resolve();
		};
		img.onerror = reject;

		img.src = url;
	});
}

export async function preloadImages() {
	await Promise.all([
		loadImageToDataUrl(textureCharacterAnimationBunny),
		loadImageToDataUrl(textureCharacterAnimationTortoise),
		loadImageToDataUrl(textureCharacterAnimationHedgehog),
		loadImageToDataUrl(textureCharacterAnimationFox),
		loadImageToDataUrl(textureCharacterAnimationFrog),
		loadImageToDataUrl(textureCharacterAnimationBadger),
		loadImageToDataUrl(textureCharacterAnimationBear),
		loadImageToDataUrl(tileClearing),
		loadImageToDataUrl(tileTrees),
		loadImageToDataUrl(tileHardwood),
		loadImageToDataUrl(tileTreesStump1),
		loadImageToDataUrl(tileTreesStump2),
		loadImageToDataUrl(tileTreesStump3),
		loadImageToDataUrl(tilePond),
		loadImageToDataUrl(tileStones),
		loadImageToDataUrl(cloud1),
		loadImageToDataUrl(cloud2),
		loadImageToDataUrl(cloud3),
		loadImageToDataUrl(cloud4),
	]);
}

export function getBunnyTexture(): string {
	return cache[textureCharacterAnimationBunny];
}
export function getTortoiseTexture(): string {
	return cache[textureCharacterAnimationTortoise];
}
export function getHedgehogTexture(): string {
	return cache[textureCharacterAnimationHedgehog];
}
export function getFoxTexture(): string {
	return cache[textureCharacterAnimationFox];
}
export function getFrogTexture(): string {
	return cache[textureCharacterAnimationFrog];
}
export function getBadgerTexture(): string {
	return cache[textureCharacterAnimationBadger];
}
export function getBearTexture(): string {
	return cache[textureCharacterAnimationBear];
}
export function getClearingTexture(): string {
	return cache[tileClearing];
}
export function getTreesTexture(): string {
	return cache[tileTrees];
}
export function getHardwoodTexture(): string {
	return cache[tileHardwood];
}
export function getTreesStump1Texture(): string {
	return cache[tileTreesStump1];
}
export function getTreesStump2Texture(): string {
	return cache[tileTreesStump2];
}
export function getTreeStump3Texture(): string {
	return cache[tileTreesStump3];
}
export function getPondTexture(): string {
	return cache[tilePond];
}
export function getStonesTexture(): string {
	return cache[tileStones];
}
export function getCloudTextures(): string[] {
	let cloudArray = [cache[cloud1], cache[cloud2], cache[cloud3], cache[cloud4]];
	return cloudArray;
}