// File for game logic. No rendering/animation code should be in here, but rendering/animation state fields are acceptable.

import { AnimationSet, Direction } from "./juice/animate";
import { Matrix, Point } from "./util";

export const EVERY_HOW_MANY_SECONDS: number = 10;
export const NUM_WAVES: number = 30;
const PREVIEW_TIME: number = 3;
const COLLISION_DISTANCE = 0.3;

export class AnimalType {
	readonly name: string;
	readonly speed: number;
	constructor(name: string, speed: number) {
		this.name = name;
		this.speed = speed;
	}
	canEnterTerrain(terrain: Terrain): boolean {
		return terrain === Terrain.Clearing;
	}
	canEnterTile(walt: Walt, coordinates: Point): boolean {
		if (!coordinates.isInBounds(walt.size)) {
			return false;
		}
		if (!(coordinates.x in walt.tiles) || !(coordinates.y in walt.tiles[coordinates.x])) {
			console.error("UMMM", walt.size, coordinates, walt);
			console.trace();
			alert("This is outrageous! (see console)");
			return false;
		}
		if (!this.canEnterTerrain(walt.tiles[coordinates.x][coordinates.y].terrain)) {
			return false;
		}
		return true;
	}
	protected tryNextHeadings(walt: Walt, me: Animal, preferredHeadings: Point[]): Point {
		for (const heading of preferredHeadings) {
			const coordinates = me.location.plus(heading).round();
			if (coordinates.equals(me.goal) || this.canEnterTile(walt, coordinates)) {
				return heading;
			}
		}
		// If nothing worked, accept our fate and attempt the first heading.
		return preferredHeadings[0];
	}
	pickNextHeading(walt: Walt, me: Animal, lastHeading: Point): Point {
		// Follow signs
		const sign = walt.tiles[me.location.x][me.location.y].sign;
		if (sign.length() > 0.1) {
			return sign;
		}

		// Without a sign, attempt to keep moving in the same direction.
		const preferredHeadings: Point[] = [lastHeading];
		// Try turning left or right with 50% chance.
		if (Math.random() < 0.5) {
			preferredHeadings.push(new Point(+lastHeading.y, +lastHeading.x));
			preferredHeadings.push(new Point(-lastHeading.y, -lastHeading.x));
		}
		else {
			preferredHeadings.push(new Point(-lastHeading.y, -lastHeading.x));
			preferredHeadings.push(new Point(+lastHeading.y, +lastHeading.x));
		}
		// Never resort to turning back. Reaching a dead end we'd rather perish.
		return this.tryNextHeadings(walt, me, preferredHeadings);
	}
}

class RabbitType extends AnimalType {
	constructor() {
		super("Rabbit", 1.5);
	}
}
export const Rabbit: AnimalType = new RabbitType();

class HedgehogType extends AnimalType {
	constructor() {
		super("Hedgehog", 0.8);
	}
	pickNextHeading(walt: Walt, me: Animal, lastHeading: Point): Point {
		// Follow signs
		const sign = walt.tiles[me.location.x][me.location.y].sign;
		if (sign.length() > 0.1) {
			return sign;
		}

		// Hedgehogs prefer to turn right.
		const preferredHeadings: Point[] = [lastHeading, Matrix.turnRight.apply(lastHeading), Matrix.turnLeft.apply(lastHeading)];

		return this.tryNextHeadings(walt, me, preferredHeadings);
	}
}
export const Hedgehog: AnimalType = new HedgehogType();

class TortoiseType extends AnimalType {
	constructor() {
		super("Tortoise", 0.3);
	}
	canEnterTerrain(terrain: Terrain): boolean {
		return super.canEnterTerrain(terrain) || terrain === Terrain.Pond;
	}
}
export const Tortoise: AnimalType = new TortoiseType();

class FoxType extends AnimalType {
	constructor() {
		super("Fox", 1.15);
	}
}
export const Fox: AnimalType = new FoxType();

class BadgerType extends AnimalType {
	constructor() {
		super("Badger", 0.7);
	}
}
export const Badger: AnimalType = new BadgerType();

class BearType extends AnimalType {
	constructor() {
		super("Bear", 0.6);
	}
}
export const Bear: AnimalType = new BearType();

class FrogType extends AnimalType {
	constructor() {
		super("Frog", 0.6);
	}
	canEnterTerrain(terrain: Terrain): boolean {
		return super.canEnterTerrain(terrain) || terrain === Terrain.Pond;
	}
}
export const Frog: AnimalType = new FrogType();

export enum Terrain {
	Clearing,
	Pond,
	Woods,
	WoodsStump1,
	WoodsStump2,
	WoodsStump3,
	HardWoods,
	HardWoodsStump1,
	HardWoodsStump2,
	HardWoodsStump3,
	Stones,
}

export class Tile {
	div: HTMLElement | null = null;
	terrain: Terrain = Terrain.Clearing;
	sign: Point = new Point(0, 0);
	signDirection: Direction | null = null;
	clicked: number = 0; // how often was I clicked?
	signDiv: HTMLElement | null = null;
	lastTileChangeTime: number = 0;
	constructor(terrain: Terrain) {
		this.terrain = terrain;
	}
	clone(): Tile {
		const copy: Tile = new Tile(this.terrain);
		copy.sign = this.sign;
		copy.signDirection = this.signDirection;
		copy.clicked = this.clicked;
		copy.lastTileChangeTime = this.lastTileChangeTime;
		// copy.div = this.div;
		// copy.signDiv = this.signDiv;
		return copy;
	}
	click(axeLevel: number): boolean {
		const changed = this._click(axeLevel);
		if (changed) {
			this.lastTileChangeTime = Date.now();
		}
		return changed;
	}
	private _click(axeLevel: number): boolean {
		if (axeLevel >= 1 && [Terrain.Woods, Terrain.WoodsStump1, Terrain.WoodsStump2, Terrain.WoodsStump3].includes(this.terrain)) {
			this.clicked++;
			if (this.clicked >= 3 && this.terrain === Terrain.Woods) {
				this.terrain = Terrain.WoodsStump1;
				return true;
			}
			if (this.clicked >= 6 && this.terrain === Terrain.WoodsStump1) {
				this.terrain = Terrain.WoodsStump2;
				return true;
			}
			if (this.clicked >= 9 && this.terrain === Terrain.WoodsStump2) {
				this.terrain = Terrain.WoodsStump3;
				return true;
			}
			if (this.clicked >= 12 && this.terrain === Terrain.WoodsStump3) {
				this.terrain = Terrain.Clearing;
				return true;
			}
		}
		if (axeLevel >= 2 && [Terrain.HardWoods, Terrain.HardWoodsStump1, Terrain.HardWoodsStump2, Terrain.HardWoodsStump3].includes(this.terrain)) {
			this.clicked++;
			if (this.clicked >= 3 && this.terrain === Terrain.HardWoods) {
				this.terrain = Terrain.HardWoodsStump1;
				return true;
			}
			if (this.clicked >= 6 && this.terrain === Terrain.HardWoodsStump1) {
				this.terrain = Terrain.HardWoodsStump2;
				return true;
			}
			if (this.clicked >= 9 && this.terrain === Terrain.HardWoodsStump2) {
				this.terrain = Terrain.HardWoodsStump3;
				return true;
			}
			if (this.clicked >= 12 && this.terrain === Terrain.HardWoodsStump3) {
				this.terrain = Terrain.Clearing;
				return true;
			}
		}
		return false;
	}
}

export enum Tool {
	Sign,
	RemoveSign,
	Axe,
}

export class Player {
	maxSigns: number = 9001;
	numSigns: number = 0;

	currentTool: Tool = Tool.Sign;

	axeLevel: number = 0;

	plantSign(tile: Tile): Direction {
		this.numSigns++;
		tile.sign = new Point(-1, 0);
		tile.signDirection = Direction.NW;
		return tile.signDirection;
	}
	removeSign(tile: Tile): boolean {
		if (tile.signDirection !== null) {
			this.numSigns--;
			tile.sign = new Point(0, 0);
			tile.signDirection = null;
			return true;
		}
		return false;
	}
	setSignDirection(tile: Tile, direction: Direction) {
		//yikes
		while (tile.signDirection !== direction) {
			this.rotateSign(tile);
		}
		return true;
	}
	rotateSign(tile: Tile): Direction {
		if (tile.signDirection === null) {
			console.error('cannot rotate sign, it doesnt exist', tile);
			throw 'cannot rotate sign, it doesnt exist';
		}
		if (tile.sign.x > +0.5) {
			tile.signDirection = Direction.SW;
		}
		else if (tile.sign.y < -0.5) {
			tile.signDirection = Direction.NW;
		}
		else if (tile.sign.x < -0.5) {
			tile.signDirection = Direction.NE;
		}
		else {
			tile.signDirection = Direction.SE;
		}
		tile.sign = Matrix.turnRight.apply(tile.sign);
		return tile.signDirection;
	}
	canPlantSign(): boolean {
		return this.numSigns < this.maxSigns;
	}
}

export enum GameState {
	Undecided,
	Defeat,
	Victory,
}

export enum LossCondition {
	WrongExit = "took the wrong exit.",
	WrongTerrain = "got lost.",
	Collision = "collided with",
}

export class Walt {
	readonly size: Point;
	readonly tiles: Tile[][] = [];
	readonly animals: Animal[] = [];
	readonly player: Player = new Player();
	time: number = -3.5;
	state: GameState = GameState.Undecided;
	readonly foundGoals: Point[] = [];
	readonly spawns: Point[] = [];
	tileChangedCallback: ((tile: Tile) => void) | null = null;
	hintCallback: ((text: string, location: Point, highlightTile: Point | null) => void) | null = null;
	stateChanged: ((newState: GameState) => void) | null = null;
	axeLevelChanged: ((newLevel: number) => void) | null = null;
	checkpointReached: (() => void) | null = null;
	checkpointsUsed: number = 0;
	lastCheckpointUsedTime: number = -100.0;
	checkpoint: Walt | null = null;
	checkpointOld: Walt | null = null;
	lossCondition: LossCondition | null = null;
	animalsResponsibleForLoss: Animal[] = [];
	constructor() {
		this.size = new Point(12, 12);

		const initialTiles: string[] = [];
		initialTiles.push("WwSWWWWWWWwW");
		initialTiles.push("WWWWWWWWwPWw");
		initialTiles.push("WWWccwcWWWwW");
		initialTiles.push("wWWWWWcWWWWW");
		initialTiles.push("WWSSWWcwWWwc");
		initialTiles.push("WWWPWWcWwWWW");
		initialTiles.push("cccccccWwWWW");
		initialTiles.push("WWWwWWcWwWww");
		initialTiles.push("WWwwwwcwcWWW");
		initialTiles.push("WPwWSWcWWwwW");
		initialTiles.push("WWWWwWcWWwSW");
		initialTiles.push("wWWWWWcWwWSS");
		function terrainFromLetter(letter: string): Terrain {
			switch (letter) {
				case "w":
					return Terrain.Woods;
				case "W":
					return Terrain.HardWoods;
				case "c":
					return Terrain.Clearing;
				case "P":
					return Terrain.Pond;
				case "S":
					return Terrain.Stones;
				default:
					return Terrain.Clearing;
			}
		}

		for (let x: number = 0; x < this.size.x; x++) {
			this.tiles.push([]);
			for (let y: number = 0; y < this.size.y; y++) {
				this.tiles[x][y] = new Tile(terrainFromLetter(initialTiles[this.size.y - y - 1][x]));
			}
		}
	}
	clone(): Walt {
		const copy: Walt = new Walt();
		for (let x: number = 0; x < this.size.x; x++) {
			for (let y: number = 0; y < this.size.y; y++) {
				copy.tiles[x][y] = this.tiles[x][y].clone();
			}
		}
		for (const animal of this.animals) {
			copy.animals.push(animal.clone());
		}
		copy.player.maxSigns = this.player.maxSigns;
		copy.player.numSigns = this.player.numSigns;
		copy.player.currentTool = this.player.currentTool;
		copy.player.axeLevel = this.player.axeLevel;
		copy.time = this.time;
		copy.state = this.state;
		copy.foundGoals.push.apply(copy.foundGoals, this.foundGoals);
		copy.spawns.push.apply(copy.spawns, this.spawns);
		copy.tileChangedCallback = this.tileChangedCallback;
		copy.hintCallback = this.hintCallback;
		copy.stateChanged = this.stateChanged;
		copy.axeLevelChanged = this.axeLevelChanged;
		copy.checkpointReached = this.checkpointReached;
		copy.checkpointsUsed = this.checkpointsUsed;
		copy.lastCheckpointUsedTime = this.lastCheckpointUsedTime;
		return copy;
	}
	registerCheckpoint(): void {
		this.checkpointOld = this.checkpoint;
		this.checkpoint = this.clone();
		if (this.checkpointReached) {
			this.checkpointReached();
		}
	}
	chopChop(coords: Point): void {
		const tile: Tile = this.tiles[coords.x][coords.y];
		if (tile.terrain !== Terrain.Clearing) {
			tile.terrain = Terrain.Clearing;
			if (this.tileChangedCallback) {
				this.tileChangedCallback(tile);
			}
		}
	}
	choppableChoppable(coords: Point): void {
		const tile: Tile = this.tiles[coords.x][coords.y];
		if (![Terrain.Woods, Terrain.WoodsStump1, Terrain.WoodsStump2, Terrain.WoodsStump3].includes(tile.terrain)) {
			tile.terrain = Terrain.Woods;
			if (this.tileChangedCallback) {
				this.tileChangedCallback(tile);
			}
		}
	}
	private static readonly goalA: Point = new Point(6, -1);
	private static readonly goalB: Point = new Point(-1, 5);
	private static readonly goalC: Point = new Point(12, 7);
	private static readonly goalD: Point = new Point(3, 12);
	private activatePreStage(stage: number): void {
		const A: Point = Walt.goalA;
		const As: Point = Walt.goalA.plus(new Point(0, +1));
		const B: Point = Walt.goalB;
		const Bs: Point = Walt.goalB.plus(new Point(+1, 0));
		const C: Point = Walt.goalC;
		const Cs: Point = Walt.goalC.plus(new Point(-1, 0));
		const D: Point = Walt.goalD;
		const Ds: Point = Walt.goalD.plus(new Point(0, -1));
		switch (stage) {
			case 0: {
				this.animals.push(new Animal(Hedgehog, A, As, B));
				if (this.hintCallback) {
					this.hintCallback("Place a sign to direct the hedgehog to its goal.", new Point(7.5, 6.0), new Point(6, 5));
				}
				this.foundGoals.push(B);
				this.spawns.push(A);
			} break;
			case 1: {
				this.animals.push(new Animal(Hedgehog, C, Cs, A));
				this.foundGoals.push(A);
				this.spawns.push(C);
			} break;
			case 2: {
				this.animals.push(new Animal(Rabbit, B, Bs, A));
				this.spawns.push(B);
			} break;
			case 3: {
				this.animals.push(new Animal(Badger, B, Bs, C));
				this.foundGoals.push(C);
				this.registerCheckpoint();
			} break;
			case 4: {
				this.chopChop(new Point(3, 10));
				this.chopChop(new Point(3, 11));
				this.player.axeLevel = 1;
				if (this.axeLevelChanged) {
					this.axeLevelChanged(this.player.axeLevel);
				}
				this.animals.push(new Animal(Tortoise, D, Ds, C));
				this.spawns.push(D);
				if (this.hintCallback) {
					this.hintCallback("You have received an axe. Use the axe to open the path.", new Point(6.0, 11.0), new Point(5, 9));
				}
			} break;
			case 5: {
				this.animals.push(new Animal(Hedgehog, A, As, C));
			} break;
			case 6: {
				this.animals.push(new Animal(Badger, B, Bs, D));
				this.foundGoals.push(D);
				// A checkpoint 3 seconds before here is set in tick().
			} break;
			case 7: {
				this.animals.push(new Animal(Hedgehog, A, As, B));
			} break;
			case 8: {
				this.animals.push(new Animal(Hedgehog, A, As, B));
				this.registerCheckpoint();
			} break;
			case 9: {
				this.animals.push(new Animal(Rabbit, D, Ds, B));
				this.animals.push(new Animal(Badger, A, As, B));
			} break;
			case 10: {
				this.chopChop(new Point(8, 4));
				this.chopChop(new Point(8, 5));
				this.animals.push(new Animal(Hedgehog, D, Ds, A));
				this.animals.push(new Animal(Tortoise, A, As, D));
				this.registerCheckpoint();
			} break;
			case 11: {
				this.animals.push(new Animal(Fox, B, Bs, D));
			} break;
			case 12: {
				this.animals.push(new Animal(Rabbit, C, Cs, B));
			} break;
			case 13: {
				this.animals.push(new Animal(Hedgehog, A, As, D));
				this.registerCheckpoint();
			} break;
			case 14: {
				this.animals.push(new Animal(Hedgehog, C, Cs, B));
			} break;
			case 15: {
				this.animals.push(new Animal(Hedgehog, A, As, C));
				this.animals.push(new Animal(Hedgehog, C, Cs, A));
			} break;
			case 16: {
				this.animals.push(new Animal(Rabbit, D, Ds, B));
			} break;
			case 17: {
				this.animals.push(new Animal(Badger, C, Cs, A));
			} break;
			case 18: {
				this.chopChop(new Point(3, 7));
				this.chopChop(new Point(8, 10));
				this.choppableChoppable(new Point(3, 8));
				this.choppableChoppable(new Point(6, 10));
				this.choppableChoppable(new Point(7, 10));
				this.choppableChoppable(new Point(8, 9));
				this.choppableChoppable(new Point(9, 8));
				this.choppableChoppable(new Point(9, 9));
				this.animals.push(new Animal(Frog, B, Bs, D));
				this.animals.push(new Animal(Hedgehog, C, Cs, A));
				if (this.hintCallback) {
					this.hintCallback("Frogs can cross ponds.", new Point(-2.0, 9.0), new Point(3, 6));
				}
				// A checkpoint 3 seconds before here is set in tick().
			} break;
			case 19: {
				this.animals.push(new Animal(Fox, B, Bs, A));
			} break;
			case 20: {
				this.animals.push(new Animal(Tortoise, D, Ds, C));
			} break;
			case 21: {
				this.animals.push(new Animal(Tortoise, C, Cs, A));
			} break;
			case 22: {
				this.animals.push(new Animal(Frog, A, As, D));
				this.registerCheckpoint();
			} break;
			case 23: {
				this.animals.push(new Animal(Frog, B, Bs, D));
			} break;
			case 24: {
				this.animals.push(new Animal(Rabbit, B, Bs, C));
				this.registerCheckpoint();
			} break;
			case 25: {
				this.animals.push(new Animal(Frog, B, Bs, D));
				this.animals.push(new Animal(Frog, D, Ds, B));
			} break;
			case 26: {
				this.animals.push(new Animal(Fox, A, As, B));
			} break;
			case 27: {
				this.animals.push(new Animal(Fox, A, As, B));
			} break;
			case 28: {
				this.animals.push(new Animal(Fox, A, As, D));
				this.animals.push(new Animal(Fox, D, Ds, A));
				this.registerCheckpoint();
			} break;
			case 29: {
				this.animals.push(new Animal(Rabbit, B, Bs, A));
				this.animals.push(new Animal(Rabbit, C, Cs, D));
			} break;
		}
	}
	private activateStage(stage: number): void {
		switch (stage) {
			case 1: {
				this.chopChop(new Point(7, 7));
				this.chopChop(new Point(8, 7));
				this.chopChop(new Point(9, 7));
				this.chopChop(new Point(10, 7));
			} break;
			case 2: {
				if (this.hintCallback) {
					// maybe show this message at a later point in time?
					//this.hintCallback("Animals must not run into each other.", new Point(3.5, 7.5), null);
				}
			} break;
		}
		for (const animal of this.animals) {
			if (animal.state === AnimalState.Waiting) {
				animal.state = AnimalState.Live;
			}
		}
	}
	private win(): void {
		if (this.state === GameState.Undecided) {
			this.state = GameState.Victory;
			if (this.stateChanged) {
				this.stateChanged(this.state);
			}
		}
	}
	private lose(animal: Animal, condition: LossCondition, animal2?: Animal): void {
		if (this.state === GameState.Undecided) {
			this.state = GameState.Defeat;
			this.lossCondition = condition;
			this.animalsResponsibleForLoss = [animal, animal2!];
			if (this.stateChanged) {
				this.stateChanged(this.state);
			}
		}
	}
	skipToStage(stage: number): void {
		this.animals.splice(0, this.animals.length);
		if (stage > 1) {
			this.chopChop(new Point(7, 7));
			this.chopChop(new Point(8, 7));
			this.chopChop(new Point(9, 7));
			this.chopChop(new Point(10, 7));
		}
		if (stage > 4) {
			this.chopChop(new Point(3, 10));
			this.chopChop(new Point(3, 11));
			this.chopChop(new Point(5, 9));
			this.player.axeLevel = 1;
			if (this.axeLevelChanged) {
				this.axeLevelChanged(this.player.axeLevel);
			}
		}
		if (stage > 10) {
			this.chopChop(new Point(7, 3));
			this.chopChop(new Point(8, 4));
			this.chopChop(new Point(8, 5));
			this.chopChop(new Point(8, 6));
		}
		if (stage > 18) {
			this.chopChop(new Point(3, 7));
			this.chopChop(new Point(8, 10));
			this.chopChop(new Point(3, 8));
			this.choppableChoppable(new Point(6, 10));
			this.choppableChoppable(new Point(7, 10));
			this.choppableChoppable(new Point(8, 9));
			this.choppableChoppable(new Point(9, 8));
			this.choppableChoppable(new Point(9, 9));
		}
		this.time = stage * EVERY_HOW_MANY_SECONDS - PREVIEW_TIME - 0.01;
	}
	tick(step: number): void {
		{
			const timeOld = this.time;
			this.time += step;
			if (Math.floor((this.time + PREVIEW_TIME) / EVERY_HOW_MANY_SECONDS) != Math.floor((timeOld + PREVIEW_TIME) / EVERY_HOW_MANY_SECONDS)) {
				this.activatePreStage(Math.floor((this.time + PREVIEW_TIME) / EVERY_HOW_MANY_SECONDS));
			}
			if (Math.floor(this.time / EVERY_HOW_MANY_SECONDS) != Math.floor(timeOld / EVERY_HOW_MANY_SECONDS)) {
				this.activateStage(Math.floor(this.time / EVERY_HOW_MANY_SECONDS));
			}
			if (this.time >= 54.0 && timeOld < 54.0) {
				this.registerCheckpoint();
			}
			if (this.time >= 174.0 && timeOld < 174.0) {
				this.registerCheckpoint();
			}
		}
		if (!this.animals.length && this.time > 0) {
			this.win();
		}
		for (const animal of this.animals) {
			if (animal.state !== AnimalState.Live) {
				continue;
			}

			let movement: number = animal.type.speed * step;
			let remaining: number = animal.location.distance(animal.nextTile);

			// First, handle reaching new tiles.
			while (remaining <= movement) {
				const lastHeading: Point = animal.heading();
				movement -= remaining;
				animal.location = animal.nextTile;

				// Arrived at the center of a tile!
				if (animal.nextTile.equals(animal.goal)) {
					animal.state = AnimalState.Arrived;
					break;
				}
				else if (!animal.nextTile.isInBounds(this.size)) {
					animal.state = AnimalState.Dying;
					this.lose(animal, LossCondition.WrongExit);
					break;
				}
				else if (!animal.type.canEnterTerrain(this.tiles[animal.nextTile.x][animal.nextTile.y].terrain)) {
					animal.state = AnimalState.Dying;
					this.lose(animal, LossCondition.WrongTerrain);
					break;
				}

				// Figure out where to go next.
				const nextHeading: Point = animal.type.pickNextHeading(this, animal, lastHeading);
				animal.nextTile = animal.nextTile.plus(nextHeading).round();

				remaining = animal.location.distance(animal.nextTile);
			}
			if (animal.state !== AnimalState.Live) {
				continue;
			}

			// Then add the remaining movement towards the next tile.
			{
				const heading = animal.heading();
				animal.location = animal.location.plus(heading.scale(movement));
				if (animal.location.distance(animal.nextTile) < 0.0001) {
					animal.location = animal.nextTile.minus(heading.scale(0.01));
				}
			}

			// Check whether we ran into another animal.
			for (const otherAnimal of this.animals) {
				if (otherAnimal !== animal && (otherAnimal.state === AnimalState.Live || otherAnimal.state === AnimalState.Dying) && otherAnimal.location.distance(animal.location) < COLLISION_DISTANCE) {
					animal.state = AnimalState.Dying;
					otherAnimal.state = AnimalState.Dying;
					this.lose(animal, LossCondition.Collision, otherAnimal);
				}
			}
		}
	}
	colorForGoal(goal: Point): string {
		if (goal.equals(Walt.goalA)) {
			return "red";
		}
		else if (goal.equals(Walt.goalB)) {
			return "yellow";
		}
		else if (goal.equals(Walt.goalC)) {
			return "cyan";
		}
		else if (goal.equals(Walt.goalD)) {
			return "black";
		}
		else {
			return "brown";
		}
	}
}

export enum AnimalState {
	Waiting,
	Live,
	Arrived,
	Dying,
}

export class Animal {
	static animalCounter = 0;
	readonly nr: number = ++Animal.animalCounter;

	readonly type: AnimalType;
	location: Point;
	spawn: Point;
	nextTile: Point;
	goal: Point;
	state: AnimalState = AnimalState.Waiting;
	div: HTMLElement | null = null;
	shadow: HTMLElement | null = null;
	animationSet: AnimationSet | null = null;
	decay: number = 100;
	constructor(type: AnimalType, start: Point, firstTile: Point, goal: Point) {
		this.type = type;
		this.spawn = start;
		this.location = start;
		this.nextTile = firstTile;
		this.goal = goal;
	}
	clone(): Animal {
		const output: Animal = new Animal(this.type, this.location, this.nextTile, this.goal);
		output.state = this.state;
		output.decay = this.decay;
		// output.div = this.div;
		// output.shadow = this.shadow;
		// output.animationSet = this.animationSet;
		return output;
	}
	heading(): Point {
		return this.nextTile.minus(this.location).normalize();
	}
	toString(): string {
		return this.type.name + " #" + this.nr;
	}
}
