import { Point, Matrix, jump, Utils } from './util'
import { EVERY_HOW_MANY_SECONDS, Walt, Tile, Terrain, Tool, Animal, AnimalState, GameState, NUM_WAVES, AnimalType } from './game'
import { createSmoke, updateParticles, createExplosion, createWoodChopParticles, createConfetti, bloodSplatter } from './juice/particles';
import { ANIMATION_TYPES, Direction, getWalkDirectionByVector, setSignImage } from './juice/animate';
import deadBunny from './assets/characters/bunny/bunny_dead.png?as=webp&width=256';
import deadBadger from './assets/characters/badger/badger_dead.png?as=webp&width=256';
import deadFox from './assets/characters/fox/fox_dead.png?as=webp&width=256';
import deadFrog from './assets/characters/frog/frog_dead.png?as=webp&width=256';
import deadTortoise from './assets/characters/tortoise/tortoise_dead.png?as=webp&width=256';
import deadHedgehog from './assets/characters/hedgehog/hedgehog_dead.png?as=webp&width=256';
import flagRed from './assets/flags/red.png';
import flagYellow from './assets/flags/yellow.png';
import flagCyan from './assets/flags/cyan.png';
import flagBlack from './assets/flags/black.png';
import flagBrown from './assets/flags/brown.png';
import goalRed from './assets/goals/red.png';
import goalYellow from './assets/goals/yellow.png';
import goalCyan from './assets/goals/cyan.png';
import goalBlack from './assets/goals/black.png';
import circle from './assets/tiles/circle.png';
import board from './assets/buttons/tutorial_board.png?as=webp&width=512';
import refreshBtn from './assets/buttons/refreshBtn.png?as=webp&width=512';
import rewind1Btn from './assets/buttons/rewind1.png?as=webp&width=512';
import rewind2Btn from './assets/buttons/rewind2.png?as=webp&width=512';
import { startBackgroundMusic, isMusicMuted, backgroundMusic, playRandomSoundOfList, muteSound, muteMusic, chopList, clankList, nochopList, putList, rotateList, splishList, slurpList, preloadSounds, destroy1, unMute, isSoundMuted, unMuteMusic, destroyList, winSound, lossSound } from './juice/sound';
/*
disable right click, we should only really do this when the map is clicked but i'm lazy
*/
import { getAnimalAnimationSet } from './juice/animations';
import { getClearingTexture, getHardwoodTexture, getPondTexture, getStonesTexture, getTreesStump1Texture, getTreesStump2Texture, getTreesTexture, getTreeStump3Texture, preloadImages, getCloudTextures } from './juice/images';

const MS_PER_FRAME: number = 1000 / 60;

const gameContainer: HTMLElement = document.getElementById("gameContainer")!;
const toolbarContainer: HTMLElement = document.getElementById("toolbar")!;

const selectSignButton = document.getElementById('setSign');
const removeSignButton = document.getElementById('deleteSign');
const muteBtn = document.getElementById('muteBtn');
const muteMusicBtn = document.getElementById('muteMusicBtn');
const axeToolIcon = document.getElementById('axeTool');
const axeTool2Icon = document.getElementById('axeTool2');

selectSignButton!.onclick = () => {
	selectTool(Tool.Sign);
}
removeSignButton!.onclick = () => {
	selectTool(Tool.RemoveSign);
}


muteBtn!.onclick = () => {
	if (isSoundMuted()) {
		unMute();
		muteMusicBtn!.hidden = false;
		muteBtn!.classList.remove('is-selected');
	} else {
		muteSound();
		muteMusicBtn!.hidden = true;
		muteBtn!.classList.add('is-selected');
	}
}

muteMusicBtn!.onclick = () => {
	if (isMusicMuted()) {
		unMuteMusic();
		muteMusicBtn!.classList.remove('is-selected');
	} else {
		muteMusic();
		muteMusicBtn!.classList.add('is-selected');
	}
}


let walt: Walt = new Walt();
let currentHover: HTMLElement | null = null;



//        screen        =       game       *           transform
//
//                                           /transform.m00, transform.m10\
// (screen.x, screen.y) = (game.x, game.y) * |                            |
//                                           \transform.m01, transform.m11/

const mat_transform: Matrix = new Matrix(+50.0, +50.0, +35.0, -35.0);
const mat_transform_inv: Matrix = mat_transform.invert();
const screen_shift: Point = new Point(0, mat_transform.apply(new Point(walt.size.x, 0)).y);
const texture_shift_tiles: Point = new Point(-1.2, +0.21);
const texture_shift_animals: Point = new Point(+0.1, -0.35);
const texture_shift_goals: Point = new Point(-1.3, +1.3);
const texture_shift_circle: Point = new Point(-0.65, +0.15);

function game_to_screen(point: Point): Point {
	return mat_transform.apply(point).plus(screen_shift);
}
function screen_to_game(point: Point): Point {
	return mat_transform_inv.apply(point.minus(screen_shift));
}

function selectTool(tool: Tool) {
	//todo check if user can use it
	walt.player.currentTool = tool;
	const oldButton = document.querySelector('.toolbar-button.is-selected');
	if (oldButton)
		oldButton.classList.remove('is-selected');

	if (tool === Tool.Sign) {
		selectSignButton!.classList.add('is-selected');
	} else if (tool === Tool.RemoveSign) {
		removeSignButton!.classList.add('is-selected');
	}
}

gameContainer.addEventListener("mousemove", function (e: MouseEvent) {
	if (!e.currentTarget) {
		return;
	}
	const coords: Point = new Point(e.pageX - e.currentTarget.offsetLeft,
		e.pageY - e.currentTarget.offsetTop);
	const target: Point = screen_to_game(coords).plus(texture_shift_tiles);
	const tile: Point = target.round();
	document.getElementById("hoverinfo")!.innerHTML = target.toString() + "<br>" + tile.toStringInt();
	// Remove style from previous hover thing.
	if (currentHover) {
		gameContainer.classList.remove('player-axe-cursor')
		currentHover.classList.remove("highlight");
		currentHover.classList.remove("tile-axe-hover");
		currentHover = null;
	}
	// Highlight new hover thing.
	if (tile.isInBounds(walt.size)) {
		const currentTile = walt.tiles[tile.x][tile.y];
		if (walt.player.axeLevel >= 1 && [Terrain.Woods, Terrain.WoodsStump1, Terrain.WoodsStump2, Terrain.WoodsStump3].includes(currentTile.terrain)) {
			currentHover = currentTile.div!;
			currentHover.classList.add("highlight");
			currentHover.classList.add("tile-axe-hover");
			gameContainer.classList.add('player-axe-cursor');
		}
		else if (currentTile.terrain === Terrain.Clearing) {
			currentHover = currentTile.div!;
			currentHover.classList.add("highlight");
		}
	}
});
let leftMouseDown: Point | null = null;
let leftMouseDownTarget: Tile | null = null;
let plantCooldown: number = 0;
function resetLeftMouseDown() {
	leftMouseDown = null;
	leftMouseDownTarget = null;
}
document.addEventListener('mouseup', resetLeftMouseDown);
document.addEventListener('mouseleave', resetLeftMouseDown);
gameContainer.addEventListener("mouseup", resetLeftMouseDown);
gameContainer.addEventListener("mousemove", function (e: any) {
	if (leftMouseDown !== null &&
		leftMouseDownTarget &&
		leftMouseDownTarget.signDirection !== null
	) {
		const coords: Point = new Point(e.pageX - e.currentTarget.offsetLeft,
			e.pageY - e.currentTarget.offsetTop);
		const direction = coords.minus(leftMouseDown);
		if (direction.length() > 10) {
			const angle = direction.angle();
			const deg = (angle * 180 / Math.PI) + 180;
			if (deg < 90) {
				walt.player.setSignDirection(leftMouseDownTarget, Direction.NW)
			} else if (deg < 180) {
				walt.player.setSignDirection(leftMouseDownTarget, Direction.NE)
			} else if (deg < 270) {
				walt.player.setSignDirection(leftMouseDownTarget, Direction.SE)
			} else {
				walt.player.setSignDirection(leftMouseDownTarget, Direction.SW)
			}
			setSignImage(leftMouseDownTarget, leftMouseDownTarget.signDirection);
			plantCooldown = new Date().getTime() + 50;
		}
	}
});

gameContainer.addEventListener("mousedown", function (e: any) {

	const isLeftClick = (e.which === 0 || e.which === 1);
	const coords: Point = new Point(e.pageX - e.currentTarget.offsetLeft,
		e.pageY - e.currentTarget.offsetTop);
	createSmoke(coords.x, coords.y);

	const tile: Point = screen_to_game(coords).plus(texture_shift_tiles).round();

	if (tile.isInBounds(walt.size)) {
		const tileObj: Tile = walt.tiles[tile.x][tile.y];
		if (isLeftClick) {
			leftMouseDown = coords;
			leftMouseDownTarget = tileObj;
		}
		if (tileObj.terrain === Terrain.Clearing) {
			// Rotate sign clockwise

			if (walt.player.currentTool === Tool.Sign) {
				if (isLeftClick !== true) {
					if (tileObj.signDirection !== null) {
						walt.player.removeSign(tileObj);
						setSignImage(tileObj, null);
						playRandomSoundOfList(slurpList);
					}
				} else {
					if (tileObj.signDirection !== null) {
						if (plantCooldown < new Date().getTime()) {
							//we have a sign, so we can rotate it
							const direction = walt.player.rotateSign(tileObj);
							setSignImage(tileObj, direction);
							playRandomSoundOfList(rotateList);
						}
					} else if (walt.player.canPlantSign()) {
						//we don't have a sign, and we can plant one
						//we have 1 second cooldown after destroying
						//we also have super short cooldown after dragging
						if (new Date().getTime() - tileObj.lastTileChangeTime > 1000 && new Date().getTime() > plantCooldown) {
							const direction = walt.player.plantSign(tileObj);
							setSignImage(tileObj, direction);
							playRandomSoundOfList(putList);

							//hide the hint if it's visible, and we're in the first 15 seconds
							if (tile.equals(new Point(6, 5)) && tileObj.signDirection === Direction.NW && walt.time < 15) {
								fancyHideHint();
							}
						}
					}
					if (tile.equals(new Point(3, 5)) && tileObj.signDirection === Direction.NE && walt.time > 177 && walt.time < 190) {
						fancyHideHint();
					}
				}
			} else if (walt.player.currentTool === Tool.RemoveSign) {
				if (tileObj.signDirection !== null) {
					walt.player.removeSign(tileObj);
					setSignImage(tileObj, null);
					playRandomSoundOfList(slurpList);
				}
			}


			if (tileObj.signDirection !== null) {
				tileObj.signDiv?.classList.remove('animatedSign');
				setTimeout(() => {
					tileObj.signDiv?.classList.add('animatedSign');
				}, 1);
			}
		}
		if ([Terrain.Woods, Terrain.WoodsStump1, Terrain.WoodsStump2, Terrain.WoodsStump3].includes(tileObj.terrain)) {
			if (walt.player.axeLevel >= 1) {
				if (tileObj.clicked >= 11 && tileObj.terrain === Terrain.WoodsStump3) {
					playRandomSoundOfList(destroyList);
				}
				else playRandomSoundOfList(chopList);
				if (tile.equals(new Point(5, 9)) && walt.time > 35 && walt.time < 50) {
					console.log(walt.time);
					fancyHideHint();
				}
				createWoodChopParticles(coords.x, coords.y);

				makeTileWiggleOnAxeHit(tileObj);
			}
			else {
				playRandomSoundOfList(nochopList);
			}
		}
		if ([Terrain.HardWoods, Terrain.HardWoodsStump1, Terrain.HardWoodsStump2, Terrain.HardWoodsStump3].includes(tileObj.terrain)) {
			if (walt.player.axeLevel >= 2) {
				if (tileObj.clicked >= 11 && tileObj.terrain === Terrain.HardWoodsStump3) {
					playRandomSoundOfList(destroyList);
				}
				else playRandomSoundOfList(chopList);
				createWoodChopParticles(coords.x, coords.y);
				makeTileWiggleOnAxeHit(tileObj);
			}
			else {
				playRandomSoundOfList(nochopList);
			}
		}
		if (tileObj.click(walt.player.axeLevel)) {
			updateTileTerrain(tileObj, 0);
		}
		if (tileObj.terrain === Terrain.Stones) {
			playRandomSoundOfList(clankList, 0.2);
		}
		if (tileObj.terrain === Terrain.Pond) {
			playRandomSoundOfList(splishList, 0.1);
		}
	}
	startBackgroundMusic();
});

function makeTileWiggleOnAxeHit(tile: Tile) {
	tile.div!.classList.add('tile-axe-hit');
	setTimeout(() => {
		tile.div!.classList.remove('tile-axe-hit');
	}, 120);
}

function loadWalt(): void {
	// Clean up any old elements
	gameContainer.textContent = "";


	gameContainer.style.height = (screen_shift.y * 2.3) + 'px';
	gameContainer.style.width = walt.size.x * 100 + 'px';
	for (let x: number = 0; x < walt.size.x; x++) {
		for (let y: number = walt.size.y - 1; y >= 0; y--) {
			const div: HTMLElement = document.createElement("div");
			div.classList.add("tile");

			const offset: Point = game_to_screen(new Point(x, y));
			div.style.left = offset.x + "px";
			div.style.top = offset.y + "px";
			if (walt.tiles[x][y].terrain !== Terrain.Clearing) {
				const randomHue = Math.floor(Math.random() * 30) - 15;
				div.style.filter = `hue-rotate(${randomHue.toFixed()}deg)`;
			}
			walt.tiles[x][y].div = div;
			gameContainer.appendChild(div);

			if (walt.tiles[x][y].signDirection !== null) {
				setSignImage(walt.tiles[x][y], walt.tiles[x][y].signDirection);
			}

			updateTileTerrain(walt.tiles[x][y], 0);
		}
	}
	for (const goal of walt.foundGoals) {
		ensureGoal(goal, walt.colorForGoal(goal));
	}
	selectTool(walt.player.currentTool);
	updateAxeDisplay(walt.player.axeLevel);
	walt.tileChangedCallback = updateTileTerrain;
	walt.axeLevelChanged = updateAxeDisplay;
	walt.hintCallback = showHint;
	walt.stateChanged = gameOver;
	walt.checkpointReached = checkpointReached;

	if (isSoundMuted()) {
		muteBtn!.classList.add('is-selected');
	}
	if (isMusicMuted()) {
		muteMusicBtn!.classList.add('is-selected');
	}
}

window.addEventListener("hashchange", function () {
	const stage: number = parseInt(window.location.hash.substring(1));
	if (!isNaN(stage)) {
		for (const animal of walt.animals) {
			animal.div?.remove();
			animal.shadow?.remove();
		}
		walt.skipToStage(stage);
		walt.registerCheckpoint();
	}
});

function checkpointReached(): void {
	const div: HTMLElement = document.createElement("div");
	div.style.padding = "20px";
	div.style.fontSize = "40px";
	div.style.color = "#FFFC67";
	div.style.textShadow = "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black";
	div.style.position = "absolute";
	div.style.right = "0"
	div.style.top = "0";
	div.textContent = "Checkpoint reached!";

	document.body.appendChild(div);
	setTimeout(function (): void {
		div.remove();
	}, 2000);
}

function makeClouds(tile: Tile) {
	let cloud = getCloudTextures()[Utils.getRndInteger(0, 3)]
	let cloudDiv: HTMLElement = document.createElement("div");
	cloudDiv.classList.add("cloud");
	cloudDiv.style.backgroundImage = `url(${cloud})`;
	let x = parseInt(tile.div!.style.left.match(/\d+/)![0]);
	let y = parseInt(tile.div!.style.top.match(/\d+/)![0]);
	x += Utils.getRndInteger(-25, 25);
	y += Utils.getRndInteger(-50, 0);
	cloudDiv.style.left = x + "px";
	cloudDiv.style.top = y + "px";
	gameContainer.appendChild(cloudDiv);
	setTimeout(function (): void {
		cloudDiv.remove();
	}, 5000);
	unfade(cloudDiv, 0.3);
	fade(cloudDiv, 0.03, 0.8);
}

function updateTileTerrain(tile: Tile, clouds: number = 3) {
	for (let i = clouds; i >= 1; i--) {
		makeClouds(tile);
	}

	switch (tile.terrain) {
		case Terrain.Clearing:
			tile.div!.style.backgroundImage = `url('${getClearingTexture()}')`;
			tile.div!.style.filter = `hue-rotate(0deg)`;
			break;
		case Terrain.Pond:
			tile.div!.style.backgroundImage = `url('${getPondTexture()}')`;
			break;
		case Terrain.Stones:
			tile.div!.style.backgroundImage = `url('${getStonesTexture()}')`;
			break;
		case Terrain.Woods:
			tile.div!.style.backgroundImage = `url('${getTreesTexture()}')`;
			break;
		case Terrain.HardWoods:
			tile.div!.style.backgroundImage = `url('${getHardwoodTexture()}')`;
			break;
		case Terrain.WoodsStump1:
			tile.div!.style.backgroundImage = `url('${getTreesStump1Texture()}')`;
			break;
		case Terrain.WoodsStump2:
			tile.div!.style.backgroundImage = `url('${getTreesStump2Texture()}')`;
			break;
		case Terrain.WoodsStump3:
			tile.div!.style.backgroundImage = `url('${getTreeStump3Texture()}')`;
			break;
		default:
			throw new Error('trying to update tile with unknown terrain ' + tile.terrain);
	}
}

function updateAxeDisplay(level: number) {
	if (level === 0) {
		axeToolIcon!.style.visibility = "hidden";
		axeTool2Icon!.style.visibility = "hidden";
	} if (level === 1) {
		axeToolIcon!.style.visibility = "";
		axeTool2Icon!.style.visibility = "hidden";
	} if (level === 2) {
		axeToolIcon!.style.visibility = "hidden";
		axeTool2Icon!.style.visibility = "";
	}
}

let hintDiv: HTMLElement | null = null;
let hintCircle: HTMLImageElement | null = null;

function clearHint() {
	if (hintDiv) {
		hintDiv.remove();
		hintDiv = null;
	}
	if (hintCircle) {
		hintCircle.remove();
		hintCircle = null;
	}

}

function fancyHideHint() {
	if (hintDiv !== null) {
		if (hintDiv) {
			hintDiv.style.transition = 'all 1.5s cubic-bezier(0.25, 1, 0.5, 1)';
			hintDiv.style.transform = 'scale(0) rotate(720deg)';
			hintDiv.style.opacity = '0';
		}
		if (hintCircle) {
			hintCircle.style.transition = 'all 1s ease-in-out';
			hintCircle.style.transform = 'scale(5) rotate(360deg)';
			hintCircle.style.opacity = '0';
		}
	}
}

function showHint(text: string, location: Point, highlightTile: Point | null): void {
	hintDiv = document.createElement("div");
	if (!hintDiv) {
		return
	}

	hintDiv.draggable = false;
	hintDiv.style.pointerEvents = 'none';
	hintDiv.classList.add("popup");
	hintDiv.style.width = "300px";
	hintDiv.style.height = "250px";
	hintDiv.style.padding = "20px";
	hintDiv.style.paddingBottom = "80px";
	hintDiv.style.color = '#FFFC67';
	hintDiv.style.textShadow = '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black';
	hintDiv.style.backgroundImage = `url('${board}')`;
	hintDiv.style.backgroundSize = "contain";
	hintDiv.style.backgroundRepeat = "no-repeat";
	hintDiv.classList.add("animatedHint");

	const offset: Point = game_to_screen(location);
	hintDiv.style.left = offset.x + "px";
	hintDiv.style.top = offset.y + "px";
	hintDiv.textContent = text;

	gameContainer.appendChild(hintDiv);

	if (highlightTile) {
		hintCircle = document.createElement("img");
		hintCircle.draggable = false;
		hintCircle.classList.add("circle");
		hintCircle.src = circle;
		const offset: Point = game_to_screen(highlightTile.plus(texture_shift_circle));
		hintCircle.style.left = offset.x + "px";
		hintCircle.style.top = offset.y + "px";
		gameContainer.appendChild(hintCircle);
	}


	setTimeout(function (): void {
		clearHint();
	}, 8000);
}

function ensureGoal(goal: Point, color: string) {
	if (document.querySelector(`.goal[data-color='{color}']`) || color === "brown") {
		return;
	}

	const imgGoal: HTMLImageElement = document.createElement("img");
	imgGoal.classList.add("goal");
	imgGoal.setAttribute("data-color", color);
	unfade(imgGoal, 0.25);
	switch (color) {
		case "red": {
			imgGoal.src = goalRed;
		} break;
		case "yellow": {
			imgGoal.src = goalYellow;
		} break;
		case "cyan": {
			imgGoal.src = goalCyan;
		} break;
		case "black": {
			imgGoal.src = goalBlack;
		} break;
		default: {
			console.log("Uh oh. Unknown goal color.");
		}
	}
	imgGoal.draggable = false;
	const offset: Point = game_to_screen(goal.plus(texture_shift_goals));
	imgGoal.style.left = offset.x + "px";
	imgGoal.style.top = offset.y + "px";
	if (goal.y < 0 || goal.y >= walt.size.y) {
		imgGoal.style.transform = "scaleX(-1)";
	}
	if (goal.y < 0 || goal.x >= walt.size.x) {
		imgGoal.style.zIndex = "+1000";
	}
	if (goal.x < 0 || goal.y >= walt.size.y) {
		imgGoal.style.zIndex = "-1000";
	}
	gameContainer.appendChild(imgGoal);
}

function updateAnimals(step: number): void {
	const animalsToInter: Animal[] = [];
	for (const animal of walt.animals) {
		if (!animal.div) {
			const div: HTMLElement = document.createElement("div");
			div.classList.add("animal");
			animal.div = div;

			const shadow: HTMLElement = document.createElement("div");
			shadow.classList.add("shadow");
			animal.shadow = shadow;

			animal.animationSet = getAnimalAnimationSet(animal.type, div, shadow);
			gameContainer.appendChild(shadow);
			gameContainer.appendChild(div);

			if (animal.state === AnimalState.Waiting) {
				div.animate([{ transform: "scale(10.0)" }, { transform: "scale(1.0)" }], 600);
				unfade(shadow);
				unfade(div);
			}
			else {
				div.style.opacity = "1.0";
			}

			const color: string = walt.colorForGoal(animal.goal);
			if (animal.state === AnimalState.Live || animal.state === AnimalState.Waiting) {
				const imgFlag: HTMLImageElement = document.createElement("img");
				imgFlag.classList.add("flag");
				switch (color) {
					case "red": {
						imgFlag.src = flagRed;
					} break;
					case "yellow": {
						imgFlag.src = flagYellow;
					} break;
					case "brown": {
						imgFlag.src = flagBrown;
					} break;
					case "black": {
						imgFlag.src = flagBlack;
					} break;
					case "cyan":
					default: {
						imgFlag.src = flagCyan;
					}
				}
				div.appendChild(imgFlag);
			}

			ensureGoal(animal.goal, color);
		}

		const jumpHeight: number = animal.animationSet ? animal.animationSet.jumpHeight * jump((animal.animationSet.jumpTime % animal.animationSet.jumpPeriod) / animal.animationSet.jumpPeriod) : 0;
		const offset: Point = game_to_screen(animal.location.minus(texture_shift_animals));
		animal.div.style.left = offset.x + "px";
		animal.div.style.top = (offset.y - jumpHeight + (animal.animationSet ? animal.animationSet?.manualYShift : 0)) + "px";
		animal.shadow!.style.left = (offset.x + 17.5) + "px";
		animal.shadow!.style.top = (offset.y + 63) + "px";
		if (animal.state === AnimalState.Live) {
			animal.shadow!.style.opacity = (1.0 - jumpHeight / 44.0).toFixed(3);
		}

		if (animal.state === AnimalState.Live || animal.state === AnimalState.Waiting) {
			const heading = animal.heading();
			const newAnimationDirection = getWalkDirectionByVector(heading.x, heading.y);
			if (newAnimationDirection != animal.animationSet!.currentAnimationConfig.type && newAnimationDirection != ANIMATION_TYPES.NONE) {
				animal.animationSet!.startAnimation(newAnimationDirection);
			}
			if (animal.state === AnimalState.Live && walt.state === GameState.Undecided) {
				animal.animationSet!.animate(step);
			}
			if (heading.x + heading.y > 0 && !animal.div.classList.contains("east")) {
				animal.div.classList.remove("west");
				animal.div.classList.add("east");
			}
			else if (heading.x + heading.y < 0 && !animal.div.classList.contains("west")) {
				animal.div.classList.remove("east");
				animal.div.classList.add("west");
			}
		}
		else {
			if (animal.div.hasChildNodes()) {
				animal.div.textContent = "";
			}
			// animate death
			animal.decay -= 1;
			if (animal.decay <= 0) {
				animal.div?.remove();
				animal.shadow?.remove();
				animalsToInter.push(animal);
			}
			else {
				let loc = game_to_screen(animal.location);
				if (animal.state === AnimalState.Arrived) {
					animal.div.style.filter = "hue-rotate(90deg)";
					if (animal.decay === 99) {
						createConfetti(loc.x + 30, loc.y + 30);
						fade(animal.div, 0.2);
						fade(animal.shadow!, 0.2);
					}
				} else {
					if (animal.decay === 99) {
						bloodSplatter(loc.x + 30, loc.y + 30);
						fade(animal.div, 0.06);
						fade(animal.shadow!, 0.06);
					}
					let deadImage = deadBunny;
					switch (animal.type.name) {
						case "Badger": {
							deadImage = deadBadger;
							break;
						}
						case "Bunny": {
							deadImage = deadBunny;
							break;
						}
						case "Fox": {
							deadImage = deadFox;
							break;
						}
						case "Frog": {
							deadImage = deadFrog;
							break;
						}
						case "Hedgehog": {
							deadImage = deadHedgehog;
							break;
						}
						case "Tortoise": {
							deadImage = deadTortoise;
							break;
						}
						default: {
							deadImage = deadBunny;
							break;
						}
					}
					animal.div.style.backgroundImage = `url('${deadImage}')`;
					animal.div.style.backgroundSize = "100%";
					animal.div.style.filter = "hue-rotate(270deg)";
				}
			}
		}
	}
	for (const animal of animalsToInter) {
		if (animal !== null) {
			walt.animals.splice(walt.animals.indexOf(animal), 1);
		}
	}
}


function fade(el: HTMLElement, interval: number = 0.1, maxOpacity: number = 1) {
	let op = maxOpacity;  // initial opacity
	let timer = setInterval(function () {
		if (op <= 0.1) {
			clearInterval(timer);
			el.style.display = 'none';
		}
		el.style.opacity = op.toString();
		el.style.filter = 'alpha(opacity=' + op * 100 + ")";
		op -= op * interval;
	}, 50);
}
function unfade(el: HTMLElement, interval: number = 0.05) {
	var op = 0.1;  // initial opacity
	el.style.display = 'block';
	var timer = setInterval(function () {
		if (op >= 1) {
			clearInterval(timer);
		}
		el.style.opacity = op.toString();
		el.style.filter = 'alpha(opacity=' + op * 100 + ")";
		op += op * interval;
	}, 10);
}


let paused = false;
function pause() {
	if (paused || walt.state !== GameState.Undecided) {
		return;
	}
	paused = true;

	const div: HTMLElement = document.createElement("div");
	div.id = "pause";
	div.classList.add("popup");
	const bounds = gameContainer.getBoundingClientRect();
	div.style.width = gameContainer.clientWidth + 'px';
	div.style.height = gameContainer.clientHeight + 'px';
	div.style.left = bounds.left + 'px';
	div.style.top = bounds.top + 'px';
	div.style.fontSize = "200px";
	div.style.position = "fixed";
	div.style.zIndex = "9001";
	div.style.textAlign = "top";
	div.style.textShadow = '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black';
	div.style.color = 'white';
	div.style.userSelect = "none";
	div.textContent = "PAUSED";

	div.addEventListener("click", unpause);
	toolbarContainer.appendChild(div);
	gameContainer.classList.add("pausedBackground");
}
function unpause() {
	if (!paused) {
		return;
	}
	paused = false;
	document.getElementById("pause")?.remove();
	gameContainer.classList.remove("pausedBackground");
}
document.addEventListener("keydown", function (e: KeyboardEvent) {
	if (e.which === 27) {
		if (!paused) {
			pause();
		}
		else {
			unpause();
		}
	}
});


function createGameOverPopupElement(img: HTMLImageElement, text: string) {
	const div = document.createElement("div");
	div.classList.add('game-over-popup-element');
	div.append(img);
	const innerDiv = document.createElement("div");
	innerDiv.classList.add('game-over-popup-text');
	innerDiv.textContent = text;
	innerDiv.style.color = 'white';
	div.append(innerDiv);
	return div;
}


function gameOver(state: GameState): void {
	let victoryText = "";
	let underText = "";
	if (state === GameState.Defeat) {
		victoryText = "GAME OVER!";
		if (walt.animalsResponsibleForLoss[1] == null) underText = "The " + walt.colorForGoal(walt.animalsResponsibleForLoss[0].goal) + " " + walt.animalsResponsibleForLoss[0].type.name + " " + walt.lossCondition;
		else underText = "The " + walt.colorForGoal(walt.animalsResponsibleForLoss[0].goal) + " " + walt.animalsResponsibleForLoss[0].type.name + " " + walt.lossCondition + " the " + walt.colorForGoal(walt.animalsResponsibleForLoss[1].goal) + " " + walt.animalsResponsibleForLoss[1].type.name + ".";
		lossSound.play();
	}
	else if (state === GameState.Victory) {
		victoryText = "VICTORY!";
		winSound.play();
	}
	const div: HTMLElement = document.createElement("div");
	div.classList.add("popup");
	const bounds = gameContainer.getBoundingClientRect();
	div.style.width = gameContainer.clientWidth + 'px';
	div.style.height = gameContainer.clientHeight + 'px';
	div.style.left = bounds.left + 'px';
	div.style.top = bounds.top + 'px';
	div.style.fontSize = "80px";
	div.style.fontWeight = "bold";
	div.style.position = "fixed";
	div.style.zIndex = "1005";
	div.style.textAlign = "top";
	div.style.textShadow = '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black';
	div.style.color = 'yellow';
	div.style.userSelect = "none";
	gameContainer.classList.add("gameOverBackground");

	//div.textContent = state === GameState.Victory ? "VICTORY!" : "GAME OVER";
	div.textContent = victoryText;

	const placeholder = document.createElement("div");
	if (state === GameState.Defeat && walt.checkpointOld)
		placeholder.style.height = '400px';
	else if (state === GameState.Defeat || state === GameState.Victory)
		placeholder.style.height = '200px';

	if (underText != "") {
		const img3 = document.createElement("img");
		img3.hidden = true;
		const underTextElement = createGameOverPopupElement(img3, underText);
		div.append(underTextElement);
	}

	if (div.textContent === "VICTORY!") {
		const img = document.createElement("img");
		img.hidden = true;
		const element = createGameOverPopupElement(img, "Well Done!");
		const img2 = document.createElement("img");
		img2.hidden = true;
		let element2;
		if (walt.checkpointsUsed === 0)
			element2 = createGameOverPopupElement(img2, "Checkpoints used: " + walt.checkpointsUsed + '! Flawless!');
		else
			element2 = createGameOverPopupElement(img2, "Checkpoints used: " + walt.checkpointsUsed);

		div.append(element);
		div.append(element2);
	}

	div.append(placeholder);
	setTimeout(() => {
		placeholder.remove();
		{
			const img: HTMLImageElement = document.createElement("img");
			img.draggable = false;
			img.style.zIndex = "10000";
			img.onclick = () => {
				gameContainer.classList.remove("gameOverBackground");
				div.remove();
				startBackgroundMusic();
				if (state === GameState.Victory) {
					newGame();
				}
				else {
					loadLastCheckpoint();
				}
			}
			img.src = state === GameState.Defeat ? rewind1Btn : refreshBtn;
			img.classList.add("play-button");
			img.style.width = "200px";
			img.style.height = "200px";

			let newElement;
			if (state === GameState.Victory) {
				newElement = createGameOverPopupElement(img, "Play Again");
			} else {
				newElement = createGameOverPopupElement(img, "return to checkpoint");
			}
			div.append(newElement);
		}

		if (state === GameState.Defeat && walt.checkpointOld && walt.lastCheckpointUsedTime === walt.checkpoint!.time) {
			const img: HTMLImageElement = document.createElement("img");
			img.draggable = false;
			img.style.zIndex = "10000";
			img.onclick = () => {
				gameContainer.classList.remove("gameOverBackground");
				div.remove();
				loadSecondToLastCheckpoint();
			}
			img.src = `${rewind2Btn}`;
			img.classList.add("play-button");
			img.style.width = "200px";
			img.style.height = "200px";

			const newElement = createGameOverPopupElement(img, "Go back 2 checkpoints");
			div.append(newElement);
		}
	}, 1000);

	toolbarContainer.appendChild(div);
	backgroundMusic.pause();

}

function newGame(): void {
	walt = new Walt();
	loadWalt();
}
function loadLastCheckpoint(): void {
	if (!walt.checkpoint) {
		newGame();
	}
	else {
		const old: Walt = walt;
		walt = walt.checkpoint;
		walt.checkpoint = old.checkpointOld;
		const temp: (() => void) | null = walt.checkpointReached;
		walt.checkpointsUsed = old.checkpointsUsed + 1;
		walt.lastCheckpointUsedTime = walt.time;
		walt.checkpointReached = null;
		walt.registerCheckpoint();
		walt.checkpointReached = temp;
		loadWalt();
	}
}
function loadSecondToLastCheckpoint(): void {
	if (!walt.checkpoint || !walt.checkpointOld) {
		newGame();
	}
	else {
		const newCheckpointsUsed = walt.checkpointsUsed + 1;
		walt = walt.checkpointOld;
		const temp: (() => void) | null = walt.checkpointReached;
		walt.checkpointsUsed = newCheckpointsUsed;
		walt.lastCheckpointUsedTime = walt.time;
		walt.checkpointReached = null;
		walt.registerCheckpoint();
		walt.checkpointReached = temp;
		loadWalt();
	}
}


let lastFrameTime = performance.now();
let lastFrameTimeLeft = 0;

const timerElement = document.getElementById("timer")!;
const wavesElement: HTMLElement = document.getElementById("waves")!;

function main() {
	const start: number = performance.now();
	let step: number = start - lastFrameTime;
	lastFrameTime = start;

	if (!paused && document.hidden) {
		pause();
	}
	if (!paused) {
		if (walt.state === GameState.Undecided) {
			walt.tick(step / 1000);
		}
		updateAnimals(step / 1000);
		updateParticles(step);

		if (walt.state === GameState.Undecided) {
			timerElement!.hidden = false;
			//old version:
			//document.getElementById("timer")!.innerText = Math.min(EVERY_HOW_MANY_SECONDS - 0.01, EVERY_HOW_MANY_SECONDS - (walt.time % EVERY_HOW_MANY_SECONDS)).toFixed(2);
			const timeLeft = Math.floor(EVERY_HOW_MANY_SECONDS - (walt.time % EVERY_HOW_MANY_SECONDS) + 1)
			if (timeLeft <= 10) {
				timerElement!.innerText = timeLeft.toString();
				if (lastFrameTimeLeft != timeLeft) {
					if (timeLeft === 10) {
						//timer started again, fancy animation time
						timerElement.classList.remove("timerAnimated");
						timerElement.classList.add("timerAnimated10");
					} else if (timeLeft === 9) {
						timerElement.classList.add("timerAnimated");
						timerElement.classList.remove("timerAnimated10");
					}
				}
			} else {
				timerElement.classList.remove("timerAnimated");
			}
			lastFrameTimeLeft = timeLeft;
		}
		else {
			timerElement!.hidden = true;
		}

		const wave: number = Math.max(1, Math.min(NUM_WAVES, 1 + Math.floor(walt.time / EVERY_HOW_MANY_SECONDS)));
		wavesElement.textContent = `Wave: ${wave}/${NUM_WAVES}`;
	}

	const end: number = performance.now();
	const duration: number = end - start;
	document.getElementById("perf")!.innerHTML = "Frame duration: " + duration.toFixed(0) + " ms<br>FPS: " + (1000 / step).toFixed(1) + "<br>Clock: " + walt.time.toFixed(2);

	setTimeout(main, Math.max(0, MS_PER_FRAME - duration));
}

/*
disable right click, we should only really do this when the map is clicked but i'm lazy
*/
document.addEventListener('contextmenu', event => event.preventDefault());


//set zoom
const screenWidthPx = window.innerWidth;
const screenHeightPx = window.innerHeight * 1.25;
const minScreenDimension = Math.min(screenWidthPx, screenHeightPx);
const zoomLevel = ((minScreenDimension / 1300) * 100).toString() + "%";
//document.body.style.zoom = zoomLevel;


let loaded = false;

Promise.all([
	preloadImages(),
	preloadSounds(),
]).then(() => {
	loadWalt();
	loaded = true;
	//main();
});

function firstStart() {
	const titleContainer = document.getElementsByClassName("titleContainer")[0];
	if (titleContainer) {
		titleContainer.remove();
		document.getElementsByClassName('titleContainerBackground')[0].classList.remove('titleContainerBackground');
	}
	plantCooldown = new Date().getTime() + 400;
	console.log('firstStart');
	if (loaded) {
		lastFrameTime = performance.now();
		document.getElementById("toolbar")!.style.visibility = "";

		const stage: number = parseInt(window.location.hash.substring(1));
		if (!isNaN(stage)) {
			walt.skipToStage(stage);
			walt.registerCheckpoint();
			loadWalt();
		}

		main();
	} else {
		setTimeout(firstStart, 10);
	}
}

document.getElementsByClassName('titleContainer')[0].addEventListener('click', () => {
	firstStart();
});