export class Point {
	x: number;
	y: number;
	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}
	plus(other: Point): Point {
		return new Point(this.x + other.x, this.y + other.y);
	}
	minus(other: Point): Point {
		return new Point(this.x - other.x, this.y - other.y);
	}
	scale(factor: number): Point {
		return new Point(this.x * factor, this.y * factor);
	}
	round(): Point {
		return new Point(Math.round(this.x), Math.round(this.y));
	}
	length(): number {
		return Math.sqrt(this.x * this.x + this.y * this.y);
	}
	distance(to: Point): number {
		return this.minus(to).length();
	}
	normalize(): Point {
		return this.scale(1 / this.distance(new Point(0, 0)));
	}
	equals(other: Point): boolean {
		return this.x === other.x && this.y === other.y;
	}
	isInBounds(bounds: Point): boolean {
		return this.x >= 0 && this.y >= 0 && this.x < bounds.x && this.y < bounds.y;
	}
	toString(): string {
		return this.x.toFixed(2) + "|" + this.y.toFixed(2);
	}
	toStringInt(): string {
		return this.x.toFixed(0) + "|" + this.y.toFixed(0);
	}
	angle(): number {
		return Math.atan2(this.y, this.x);
	}
}

export function jump(t: number): number {
	const o: number = (t - 0.5) * 2
	return -o * o + 1.0;
}

export class Utils {
	static getRndInteger(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
}


export class Matrix {
	m00: number;
	m01: number;
	m10: number;
	m11: number;
	constructor(m00: number, m01: number, m10: number, m11: number) {
		this.m00 = m00;
		this.m01 = m01;
		this.m10 = m10;
		this.m11 = m11;
	}
	apply(point: Point): Point {
		return new Point(this.m00 * point.x + this.m01 * point.y,
			this.m10 * point.x + this.m11 * point.y);
	}
	invert(): Matrix {
		const det: number = this.m00 * this.m11 - this.m01 * this.m10;
		return new Matrix(this.m11 / det, this.m01 / -det, this.m10 / -det, this.m00 / det);
	}
	round(): Matrix {
		return new Matrix(Math.round(this.m00), Math.round(this.m01), Math.round(this.m10), Math.round(this.m11));
	}
	static rotate(angle: number): Matrix {
		return new Matrix(Math.cos(angle), -Math.sin(angle), Math.sin(angle), -Math.cos(angle));
	}
	static turnRight: Matrix = Matrix.rotate(-Math.PI / 2).round();
	static turnLeft: Matrix = Matrix.rotate(+Math.PI / 2).round();
}
