import { Tile } from "../game";
import textureSignSe from '../assets/signs/sign_se.png?as=webp&width=256';
import textureSignNe from '../assets/signs/sign_ne.png?as=webp&width=256';
import textureSignNw from '../assets/signs/sign_nw.png?as=webp&width=256';
import textureSignSw from '../assets/signs/sign_sw.png?as=webp&width=256';


export enum Direction {
	SE,
	SW,
	NW,
	NE,
}

export enum ANIMATION_TYPES {
	NONE = 'NONE',
	WALKING_SE = 'WALKING_SE',
	WALKING_NE = 'WALKING_NE',
	WALKING_NW = 'WALKING_NW',
	WALKING_SW = 'WALKING_SW'
}

export function getWalkDirectionByVector(x: number, y: number): ANIMATION_TYPES {
	if (x > 0.5) {
		return ANIMATION_TYPES.WALKING_SE;
	} else if (x < -0.5) {
		return ANIMATION_TYPES.WALKING_NW;
	} else if (y > 0.5) {
		return ANIMATION_TYPES.WALKING_NE;
	} else if (y < -0.5) {
		return ANIMATION_TYPES.WALKING_SW;
	}
	return ANIMATION_TYPES.NONE;
}

export type AnimationConfig = {
	type: ANIMATION_TYPES;
	numFrames: number;
	column: number;
	frameDurationSeconds: number;
}

export class AnimationSet {
	imageUrl: string;

	lastFrameRemainder: number;
	currentFrame: number;
	element: HTMLElement;
	loopAnimation: boolean;
	numAnimationConfigs: number;

	manualYShift: number = 0;

	jumpHeight: number;
	jumpPeriod: number;
	jumpTime: number = 0.0;

	private animationConfigsForType: { [key: string]: AnimationConfig } = {};
	currentAnimationConfig: AnimationConfig;

	constructor(imageUrl: string, element: HTMLElement, animationConfigs: AnimationConfig[], jumpHeight: number, jumpPeriod: number, manualYShift: number = 0) {
		if (animationConfigs.length <= 0) {
			throw new Error("Animation must have at least one config");
		}

		this.imageUrl = imageUrl;
		this.lastFrameRemainder = 0;
		this.currentFrame = 0;
		this.loopAnimation = true;
		this.manualYShift = manualYShift;
		this.jumpHeight = jumpHeight;
		this.jumpPeriod = jumpPeriod;
		this.element = element;
		this.element.style.backgroundImage = "url(" + this.imageUrl + ")";

		for (let i = 0; i < animationConfigs.length; i++) {
			const config = animationConfigs[i];
			if (this.animationConfigsForType[config.type]) {
				throw new Error("Animation config for type " + config.type + " already exists");
			}
			this.animationConfigsForType[config.type] = config;
		}
		this.numAnimationConfigs = animationConfigs.length;
		//default to no animation
		if (this.animationConfigsForType[ANIMATION_TYPES.NONE]) {
			this.currentAnimationConfig = this.animationConfigsForType[ANIMATION_TYPES.NONE];
		} else {
			//fallback to first animation
			this.currentAnimationConfig = animationConfigs[0];
		}
		this.element.style.backgroundPositionX = (this.currentAnimationConfig.column * 33.33) + '%';
		this.element.style.backgroundSize = this.getCurrentFrameSize();
	}

	startAnimation(type: ANIMATION_TYPES, loop: boolean = true) {
		if (!this.animationConfigsForType[type]) {
			throw new Error("Animation config for type " + type + " does not exist");
		}
		this.currentAnimationConfig = this.animationConfigsForType[type];
		this.currentFrame = 0;
		this.loopAnimation = loop;
		this.element.style.backgroundPositionX = (this.currentAnimationConfig.column * 33.33) + '%';
	}

	getCurrentFrameSize() {
		const framePercentageY = (100 * this.currentAnimationConfig.numFrames).toFixed(2);
		const framePercentageX = (100 * this.numAnimationConfigs);
		return framePercentageX + '% ' + framePercentageY + '%';
	}

	animate(step: number) {
		this.lastFrameRemainder += step;
		if (this.lastFrameRemainder > this.currentAnimationConfig.frameDurationSeconds) {
			const numFrames = Math.floor(this.lastFrameRemainder / this.currentAnimationConfig.frameDurationSeconds);
			this.currentFrame = (this.currentFrame + numFrames) % this.currentAnimationConfig.numFrames;
			this.lastFrameRemainder -= numFrames * this.currentAnimationConfig.frameDurationSeconds;
			this.element.style.backgroundPositionY = (this.currentFrame * 100) + '%';
		}
		this.jumpTime += step;
	}
}

export function wiggleElement(element: HTMLElement, durationMs: number) {
	element.classList.add('juice-wiggle');
	setTimeout(() => {
		element.classList.remove('juice-wiggle');
	}, durationMs);
}

export function setSignImage(tile: Tile, direction: Direction | null) {
	if (!tile.signDiv) {
		tile.signDiv = document.createElement("div");
		tile.signDiv.classList.add("sign");
		tile.div?.appendChild(tile.signDiv);
	}
	switch (direction) {
		case Direction.SE:
			tile.signDiv.style.backgroundImage = `url(${textureSignSe})`;
			tile.signDiv.style.left = '30px';
			tile.signDiv.style.top = '30px';
			break;
		case Direction.NE:
			tile.signDiv.style.backgroundImage = `url(${textureSignNe})`;
			tile.signDiv.style.left = '50px';
			tile.signDiv.style.top = '0px';
			break;
		case Direction.NW:
			tile.signDiv.style.backgroundImage = `url(${textureSignNw})`;
			tile.signDiv.style.left = '0px';
			tile.signDiv.style.top = '0px';
			break;
		case Direction.SW:
			tile.signDiv.style.backgroundImage = `url(${textureSignSw})`;
			tile.signDiv.style.left = '10px';
			tile.signDiv.style.top = '20px';
			break;
		case null:
			tile.signDiv.style.backgroundImage = ``;
			break;
	}
}