const buttonClickSuccessSound = require("../assets/sounds/button_click_success.mp3");
const backgroundMusicSound = require("../assets/sounds/backgroundMusic.mp3");
const chop1 = require("../assets/sounds/chop1.mp3");
const chop2 = require("../assets/sounds/chop2.mp3");
const chop3 = require("../assets/sounds/chop3.mp3");
const chop4 = require("../assets/sounds/chop4.mp3");
const chop5 = require("../assets/sounds/chop5.mp3");
const clank1 = require("../assets/sounds/clank1.mp3");
const clank2 = require("../assets/sounds/clank2.mp3");
const clank3 = require("../assets/sounds/clank3.mp3");
const clank4 = require("../assets/sounds/clank4.mp3");
const put1 = require("../assets/sounds/put1.mp3");
const put2 = require("../assets/sounds/put2.mp3");
const put3 = require("../assets/sounds/put3.mp3");
const put4 = require("../assets/sounds/put4.mp3");
const rotate1 = require("../assets/sounds/rotate1.mp3");
const rotate2 = require("../assets/sounds/rotate2.mp3");
const rotate3 = require("../assets/sounds/rotate3.mp3");
const rotate4 = require("../assets/sounds/rotate4.mp3");
const splish1 = require("../assets/sounds/splish1.mp3");
const splish2 = require("../assets/sounds/splish2.mp3");
const splish3 = require("../assets/sounds/splish3.mp3");
const slurp1 = require("../assets/sounds/slurp1.mp3");
const slurp2 = require("../assets/sounds/slurp2.mp3");
const slurp3 = require("../assets/sounds/slurp3.mp3");
const destroy1 = require("../assets/sounds/destroy1.mp3");
const nochop1 = require("../assets/sounds/nochop1.mp3");
const nochop2 = require("../assets/sounds/nochop2.mp3");
const nochop3 = require("../assets/sounds/nochop3.mp3");
const nochop4 = require("../assets/sounds/nochop4.mp3");
const nochop5 = require("../assets/sounds/nochop5.mp3");
const thud1 = require("../assets/sounds/thud1.mp3");
const win = require("../assets/sounds/win.mp3");
const loss = require("../assets/sounds/loss.mp3");


const IS_MUTED_LOCALSTORAGE = 'ld51-is-muted';
let muted = localStorage.getItem(IS_MUTED_LOCALSTORAGE) === 'true';

const IS_MUTED_MUSIC_LOCALSTORAGE = 'ld51-is-muted-music';
let mutedMusic = localStorage.getItem(IS_MUTED_MUSIC_LOCALSTORAGE) === 'true';


function loadAudio(url: string, vol: number): HTMLAudioElement {
	var audio = new Audio(url);
	audio.volume = vol;
	return audio;
}

export function unMute() {
	localStorage.setItem(IS_MUTED_LOCALSTORAGE, 'false');
	muted = false;
	startBackgroundMusic();
}

export function unMuteMusic() {
	localStorage.setItem(IS_MUTED_MUSIC_LOCALSTORAGE, 'false');
	mutedMusic = false;
	startBackgroundMusic();
}

export function playAudio(audio: any) {
	if (!muted) {
		try {
			audio.play();
		} catch (e) {

		}
	}
}

export function isSoundMuted(): boolean {
	return muted;
}

export function isMusicMuted(): boolean {
	return mutedMusic;
}

export async function preloadSounds() {
	loadAudio(buttonClickSuccessSound, 0.5);
	loadAudio(backgroundMusicSound, 0.5);
	loadAudio(chop1, 0.5);
	loadAudio(chop2, 0.5);
	loadAudio(chop3, 0.5);
	loadAudio(chop4, 0.5);
	loadAudio(chop5, 0.5);
	loadAudio(clank1, 0.5);
	loadAudio(clank2, 0.5);
	loadAudio(clank3, 0.5);
	loadAudio(clank4, 0.5);
	loadAudio(put1, 0.5);
	loadAudio(put2, 0.5);
	loadAudio(put3, 0.5);
	loadAudio(put4, 0.5);
	loadAudio(rotate1, 0.5);
	loadAudio(rotate2, 0.5);
	loadAudio(rotate3, 0.5);
	loadAudio(rotate4, 0.5);
	loadAudio(splish1, 0.5);
	loadAudio(splish2, 0.5);
	loadAudio(splish3, 0.5);
	loadAudio(slurp1, 0.5);
	loadAudio(slurp2, 0.5);
	loadAudio(slurp3, 0.5);
	loadAudio(destroy1, 0.5);
	loadAudio(nochop1, 0.5);
	loadAudio(nochop2, 0.5);
	loadAudio(nochop3, 0.5);
	loadAudio(nochop4, 0.5);
	loadAudio(nochop5, 0.5);
	loadAudio(thud1, 0.5);
	loadAudio(win, 0.5);
	loadAudio(loss, 0.5);
}

export function playRandomSoundOfList(sounds: string[], vol: number = 0.05) {
	if (!muted) {
		loadAudio(sounds[Math.floor(Math.random() * sounds.length)], vol).play();
	}
}

export function muteSound() {
	localStorage.setItem(IS_MUTED_LOCALSTORAGE, 'true');
	muted = true;
	var audios = document.getElementsByTagName('audio');
	for (var i = 0, len = audios.length; i < len; i++) {
		audios[i].pause();
	}
	backgroundMusic.pause();
}

export function muteMusic() {
	localStorage.setItem(IS_MUTED_MUSIC_LOCALSTORAGE, 'true');
	mutedMusic = true;
	backgroundMusic.pause();
}

export function startBackgroundMusic() {
	if (muted || mutedMusic) {
		return;
	}
	if (backgroundMusic.paused) {
		backgroundMusic.loop = true;
		playAudio(backgroundMusic);
	}
}

export const winSound = loadAudio(win, 0.2);
export const lossSound = loadAudio(loss, 0.2);

export const destroyList = [destroy1];
export const soundButtonClickSuccess = loadAudio(buttonClickSuccessSound, 0.5);
export const backgroundMusic = loadAudio(backgroundMusicSound, 0.5);
export const chopList = [chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop4, chop5, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop1, chop2, chop3];
export const clankList = [clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank1, clank2, clank4, clank3];
export const putList = [put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put1, put2, put4, put3];
export const rotateList = [rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate1, rotate2, rotate4, rotate3];
export const splishList = [splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish1, splish2, splish3];
export const slurpList = [slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp1, slurp2, slurp3];
export const nochopList = [nochop1, nochop2, nochop3, nochop4, nochop5];