import textureParticleSmokeGrey from '../assets/particles/particle_smoke_grey.png?as=webp&width=128';
import textureParticleExplosion from '../assets/particles/particle_explosion.png?as=webp&width=128';
import textureParticleWood from '../assets/particles/particle_wood.png';
import textureParticleConfetti from '../assets/particles/particle_confetti.png';
import textureParticleBlood from '../assets/particles/blood.png?as=webp&width=128';

const liveParticles: Particle[] = [];
const gameContainer = document.getElementById("gameContainer");

type Particle = {
	x: number,
	y: number,
	speedX: number,
	speedY: number,
	speedDecayX: number,
	speedDecayY: number,
	remainingLifeMs: number,
	startLifeMs: number,
	element: any,
	gravity: number,
	rotation: number,
	rotationSpeed: number,
	maxSpeedY: number
	randomRotationDirection: boolean
	otherFilters: string | null,
	transitionOpacity: boolean
}

type CreateParticlesOverrides = {
	speedX?: number,
	speedY?: number,
	speedDecayX?: number,
	speedDecayY?: number,
	life?: number,
	elementWidth?: string,
	elementHeight?: string,
	gravity?: number,
	rotation?: number,
	rotationSpeed?: number,
	maxSpeedY?: number,
	randomRotationDirection?: boolean,
	randomColor?: boolean,
	transitionOpacity?: boolean
}

function createParticles(x: number, y: number, url: string, overrides: CreateParticlesOverrides = {}) {
	const maxSpeed = 100;
	const life = Math.random() * 500;
	const particle: Particle = {
		x,
		y,
		speedX: overrides.speedX || Math.random() * (maxSpeed * 2) - maxSpeed,
		speedY: overrides.speedY || Math.random() * (maxSpeed * 2) - maxSpeed,
		speedDecayX: overrides.speedDecayX || 0.99,
		speedDecayY: overrides.speedDecayY || 0.99,
		remainingLifeMs: overrides.life || life,
		startLifeMs: overrides.life || life,
		element: document.createElement("div"),
		gravity: overrides.gravity || 0,
		rotation: overrides.rotation || 0,
		rotationSpeed: overrides.rotationSpeed || 0,
		maxSpeedY: overrides.maxSpeedY || 0,
		randomRotationDirection: overrides.randomRotationDirection || false,
		otherFilters: overrides.randomColor ? `hue-rotate(${Math.floor(Math.random() * 360)}deg)` : null,
		transitionOpacity: overrides.transitionOpacity || false
	};
	particle.element.className = 'particle';
	particle.element.style.backgroundImage = url;
	particle.element.style.backgroundSize = '100%';
	particle.element.style.left = x + 'px';
	particle.element.style.top = y + 'px';
	if (overrides.elementWidth) {
		particle.element.style.width = overrides.elementWidth;
	}
	if (overrides.elementHeight) {
		particle.element.style.height = overrides.elementHeight;
	}
	if (gameContainer) {
		gameContainer.appendChild(particle.element);
	}
	liveParticles.push(particle);
}

export function createSmoke(x: number, y: number) {
	for (let i = 0; i < 10; i++) {
		createParticles(x, y, `url('${textureParticleSmokeGrey}')`);
	}
}

export function createExplosion(x: number, y: number) {
	for (let i = 0; i < 10; i++) {
		createParticles(x, y, `url('${textureParticleExplosion}')`);
	}
}

export function bloodSplatter(x: number, y: number) {
	for (let i = 0; i < (500 + Math.floor(Math.random() * 30)); i++) {
		const angle = Math.PI * 2;
		//idk wtf is happening here but it works
		const direction = Math.random() * angle + Math.PI + angle * 2;
		let speed = 200 + Math.random() * 250;
		speed *= 1.5;
		const speedX = Math.cos(direction) * speed /4;
		const speedY = Math.sin(direction) * speed /2;
		createParticles(x, y, `url('${textureParticleBlood}')`, {
			speedX: speedX,
			speedY: speedY,
			speedDecayX: 0.95 + Math.random() * 0.01,
			speedDecayY: 1,
			life: 100 + Math.random() * 1000,
			elementWidth: '10px',
			elementHeight: '10px',
			gravity: 400,
			rotation: Math.random() * 360,
			rotationSpeed: Math.random() * 50000 - 100000,
			maxSpeedY: 20,
			randomRotationDirection: true,
			transitionOpacity: true
		});
	}
}

export function createConfetti(x: number, y: number) {
	for (let i = 0; i < (50 + Math.floor(Math.random() * 30)); i++) {
		const angle = Math.PI / 5;
		//idk wtf is happening here but it works
		const direction = Math.random() * angle + Math.PI + angle * 2;
		let speed = 600 + Math.random() * 250;
		speed *= 1.5;
		const speedX = Math.cos(direction) * speed;
		const speedY = Math.sin(direction) * speed;
		createParticles(x, y, `url('${textureParticleConfetti}')`, {
			speedX: speedX,
			speedY: speedY,
			speedDecayX: 0.95 + Math.random() * 0.01,
			speedDecayY: 1,
			life: 1000 + Math.random() * 3000,
			elementWidth: '10px',
			elementHeight: '10px',
			gravity: 3500,
			rotation: Math.random() * 360,
			rotationSpeed: Math.random() * 50000 - 100000,
			maxSpeedY: 20,
			randomRotationDirection: true,
			randomColor: true,
			transitionOpacity: true
		});
	}
}

export function createWoodChopParticles(x: number, y: number) {
	const num = Math.ceil(2 + Math.random() * 4);
	for (let i = 0; i < num; i++) {
		const particleSize = Math.floor(Math.random() * 20 + 10);
		const life = Math.random() * 500 + 50;
		createParticles(x, y, `url('${textureParticleWood}')`, {
			speedX: Math.random() * 500 - 250,
			speedY: Math.random() * -500, //more likely to go up
			speedDecayX: 0.999,
			life: life,
			elementHeight: particleSize + 'px',
			elementWidth: particleSize + 'px',
			gravity: 100 + Math.random() * 100,
			rotation: Math.random() * 360,
			rotationSpeed: Math.random() * 3600 - 1800
		});
	}
}

export function updateParticles(msPassed: number) {
	for (let i = 0; i < liveParticles.length; i++) {
		const particle = liveParticles[i];
		particle.x += particle.speedX * msPassed / 1000;
		particle.y += particle.speedY * msPassed / 1000;
		particle.speedX *= particle.speedDecayX;
		particle.speedY *= particle.speedDecayY;
		if (particle.speedY > particle.maxSpeedY)
			particle.speedY = particle.maxSpeedY;
		particle.remainingLifeMs -= msPassed;
		if (particle.element) {
			particle.element.style.left = particle.x + 'px';
			particle.element.style.top = particle.y + 'px';
		}
		if (particle.gravity) {
			particle.speedY += particle.gravity * msPassed / 1000;
		}
		if (particle.rotationSpeed > 0) {
			particle.rotation += particle.rotationSpeed * msPassed / 1000;
			particle.element.style.transform = `rotate(${Math.floor(particle.rotation) % 360}deg)`;
		}
		if (particle.transitionOpacity) {
			particle.element.style.opacity = `${particle.remainingLifeMs / particle.startLifeMs}`;
		}
		if (particle.randomRotationDirection) {
			particle.element.style.transform = `rotate3d(0, 1, 1, ${Math.floor(particle.rotation) % 360}deg)`;
		}
		if (particle.otherFilters) {
			particle.element.style.filter = particle.otherFilters;
		}
		if (particle.remainingLifeMs <= 0) {
			if (particle.element)
				particle.element.remove();
			liveParticles.splice(i, 1);
			i--;
		}
	}
}