import { AnimationSet, AnimationConfig, ANIMATION_TYPES } from './animate';
import { AnimalType } from '../game';
import { getBearTexture, getBadgerTexture, getFoxTexture, getFrogTexture, getBunnyTexture, getHedgehogTexture, getTortoiseTexture } from './images';

const defaultFrameDuration = 0.14;

// BUNNY

const bunnyWalkSE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 0
}

const bunnyWalkSW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 1
}

const bunnyWalkNW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 2
}

const bunnyWalkNE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 3
}

// TORTOISE

const tortoiseWalkSE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 0
}

const tortoiseWalkSW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 1
}

const tortoiseWalkNW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 2
}

const tortoiseWalkNE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 3
}

// HEDGEHOG

const hedgehogWalkSE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 0
}

const hedgehogWalkSW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 1
}

const hedgehogWalkNW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 2
}

const hedgehogWalkNE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 3
}

export function getAnimalAnimationSet(animalType: AnimalType, element: HTMLElement, shadow: HTMLElement): AnimationSet {
	if (animalType.name === 'Rabbit') {
		return new AnimationSet(
			getBunnyTexture(),
			element,
			[bunnyWalkSW, bunnyWalkSE, bunnyWalkNW, bunnyWalkNE],
			15.0, 0.5,
		)
	} else if (animalType.name === 'Tortoise') {
		return new AnimationSet(
			getTortoiseTexture(),
			element,
			[tortoiseWalkSW, tortoiseWalkSE, tortoiseWalkNW, tortoiseWalkNE],
			0.0, 0.4, 7
		)
	} else if (animalType.name === 'Hedgehog') {
		return new AnimationSet(
			getHedgehogTexture(),
			element,
			[hedgehogWalkSW, hedgehogWalkSE, hedgehogWalkNW, hedgehogWalkNE],
			2.0, 0.4,
		)
	} else if (animalType.name === 'Fox') {
		return new AnimationSet(
			getFoxTexture(),
			element,
			[foxWalkSW, foxWalkSE, foxWalkNW, foxWalkNE],
			2.0, 0.4,
		)
	} else if (animalType.name === 'Frog') {
		return new AnimationSet(
			getFrogTexture(),
			element,
			[frogWalkSW, frogWalkSE, frogWalkNW, frogWalkNE],
			15.0, 0.7,
		)
	} else if (animalType.name === 'Badger') {
		return new AnimationSet(
			getBadgerTexture(),
			element,
			[badgerWalkSW, badgerWalkSE, badgerWalkNW, badgerWalkNE],
			2.0, 0.4, 1
		)
	} else if (animalType.name === 'Bear') {
		return new AnimationSet(
			getBearTexture(),
			element,
			[bearWalkSW, bearWalkSE, bearWalkNW, bearWalkNE],
			2.0, 0.4,
		)
	} else {
		throw 'no animation set for animal type ' + animalType.name + '. add it in animations.ts';
	}
}

const foxWalkSE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 0
}

const foxWalkSW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 1
}

const foxWalkNW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 2
}

const foxWalkNE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 3
}

const frogWalkSE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 0
}

const frogWalkSW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 1
}

const frogWalkNW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 2
}

const frogWalkNE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 3
}

const badgerWalkSE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 0
}

const badgerWalkSW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 1
}

const badgerWalkNW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 2
}

const badgerWalkNE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 3
}

const bearWalkSE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 0
}

const bearWalkSW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_SW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 1
}

const bearWalkNW: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NW,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 2
}

const bearWalkNE: AnimationConfig = {
	type: ANIMATION_TYPES.WALKING_NE,
	frameDurationSeconds: defaultFrameDuration,
	numFrames: 2,
	column: 3
}